<template>
  <div>
    <Header :headerIndex="9"></Header>
    <div class="container">
      <img class="g_top_banner" src="../assets/static/icon/icon_top_banner9.png" alt="" />
      <div class="max_1440 distributors_wrap">
        <div style="color:white;" v-html="training.content"></div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
import api from "../api";
export default {
  components: {},
  data() {
    return {
      training:[]
    };
  },
  created() {
    this.gettraining()
  },
  methods: {
    gettraining() {
      api.doPost("/api/distributors/gettraining", {}, (res) => {
        // console.log(res)
        if(res.code == 200){
            this.training = res.data
        }
      });
    },
  },
};
</script>

<style scoped>
.distributors_wrap {
  padding: 60px 0;
}

@media screen and (max-width: 1440px) {
  .distributors_wrap {
    padding: 45px 0;
  }
}

@media screen and (max-width: 768px) {
  .distributors_wrap {
    padding: 24px 0;
  }
}
</style>
